<template>
    <div class="col-12 card mt-3">

        <TabView lazy :activeIndex="activeIndex">
            <TabPanel>
                <template #header>
                    <i class="fas fa-donate"></i>
                    <span class="pl-1">Reservas</span>
                </template>
                <Reservation/>
            </TabPanel>
        </TabView>
    </div>
</template>

<script>

    import Reservation from './Reservation'

    export default {
        components:{
            Reservation,
        },
        data(){
            return{
                activeIndex: 0,
                isLoading: false,
            }
        },
    }

</script>
