<template>
    <div>
        <!-- Filtros -->
        <div class="grid shadow-1 mt-2 px-2">
            <div class="col-12">
                <Message severity="info" :closable="false">
                    Desde aqui puede filtrar y exportar las reservaciones realizadas, combinando los filtros disponibles. 
                </Message>
            </div>
            <div class="col-12">
                <Divider align="left">
                    <div class="inline-flex align-items-center">
                        <i class="fas fa-user"></i>
                        <span class="pl-2">FILTROS</span>
                    </div>
                </Divider>
            </div>

            <!-- <div class="field col-12 md:col-3 sm:col-6">
                <span class="p-float-label">
                    <Calendar 
                        id="filter_from" 
                        ref="filter_from" 
                        v-model="filter.from" 
                        class="inputfield w-full"
                        autocomplete="off"
                        dateFormat="dd-mm-yy"
                        :manualInput="false"
                    />
                    <label for="filter_from">Fecha Desde</label>
                </span>
            </div>

            <div class="field col-12 md:col-3 sm:col-6">
                <span class="p-float-label">
                    <Calendar 
                        id="filter_to" 
                        ref="filter_to" 
                        v-model="filter.to" 
                        class="inputfield w-full"
                        autocomplete="off"
                        dateFormat="dd-mm-yy"
                        :manualInput="false"
                    />
                    <label for="filter_to">Fecha Hasta</label>
                </span>
            </div> -->

            <div class="field col-12 md:col-3 sm:col-6">
                <NightPicker :multiple="true" @handleChange="handleNight"/>
            </div>

            <div class="field col-12 md:col-3 sm:col-6">
                <span class="p-float-label">
                    <MultiSelect 
                        id="filter_user" 
                        ref="filter_user" 
                        v-model="filter.user" 
                        optionValue="id"
                        optionLabel="fullName"
                        :filter="true"
                        :options="users"
                        :showClear="true" 
                        class="w-full"
                    />
                    <label for="filter_user">Usuario</label>
                </span>
            </div>

            <div class="col-12 md:col-3 sm:col-6">
                <Button 
                    label="Filtrar" 
                    icon="fas fa-search"
                    @click="handleFilter()"
                    class="p-button-outlined p-button-sm "/>
                <Button 
                  icon="fas fa-file-csv" 
                  label="Exportar"
                  title="Exportar listado actual a CSV"
                  v-bind:disabled="!dataSet.length" 
                  class="p-button-sm ml-2"
                  @click="exportCSV($event)" />
            </div>
        </div>

        <!-- Encabezado -->
        <div class="grid shadow-1 my-4" v-if="dataSet.length">
            <div class="col-12">
                <DataTable :value="group" responsiveLayout="scroll" :rowHover="true">
                    <Column field="user.last_name" header="Usuario">
                        <template #body="row">{{  row.data.user.last_name + ', ' + row.data.user.first_name  }}</template>
                    </Column>
                    <Column field="cantidad" header="Reservas"/>
                    <Column field="total" header="Total">
                        <template #body="row">{{  this.design.Currency(row.data.total)  }}</template>
                    </Column>
                    <template #footer>
                       Reservas totales por:  {{ this.design.Currency(total) }}
                    </template>
                </DataTable>
            </div>
        </div>

        <!-- Resultados -->
        <div class="grid shadow-1">
            <div class="col-12" v-if="dataSet.length">
                <DataTable 
                    :value="dataSet" 
                    :rows="20"
                    :paginator="true" 
                    ref="dtDonations"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    responsiveLayout="scroll" 
                    :rowHover="true"
                    v-model:filters="filters" 
                    filterDisplay="row"
                    :globalFilterFields="['id', 'amount', 'person.username', 'person.last_name', 'person.first_name', 'user.last_name', 'user.first_name']"
                >
                    <template #header>
                        <div class="flex justify-content-start">
                            <span class="p-input-icon-left ">
                                <i class="fas fa-search" />
                                <InputText v-model="filters['global'].value" class="ml-2" placeholder="Filtrar Datos" />
                            </span>
                        </div>
                    </template>

                    <template #empty>{{ strings.es.dataTable.empty }}</template>
                    <template #loading>{{ strings.es.dataTable.loading }}</template>

                    <Column field="id" header="ID" :sortable="true" :style="{width:'50px'}"/>
                    <Column field="place.name" :sortable="true" header="Ubicación"/>
                    <Column :sortable="true" field="place.row" header="Fila"/>
                    <Column :sortable="true" field="place.group.name" header="Sector"/>

                    <Column 
                        field="extra"
                        :sortable="true" 
                        header="Silla/Banqueta">
                        <template #body="row">{{ row.data.extra ? 'SI' : 'NO' }}</template>
                    </Column>

                    <Column 
                        field="precio"
                        :sortable="true" 
                        header="Precio">
                        <template #body="row">{{ this.design.Currency(row.data.precio) }}</template>
                    </Column>

                    <Column 
                        field="reservation_date"
                        :sortable="true" 
                        header="Fecha Reserva" 
                        :style="{width:'130px'}">
                        <template #body="row">{{ (row.data.reservation_date != null) ? $moment.utc(row.data.reservation_date, 'YYYY-MM-DD').format("DD-MM-YYYY") : '' }}</template>
                    </Column>

                    <Column 
                        field="user.username"
                        :sortable="true" 
                        header="Usuario">
                        <template #body="row">{{  row.data.user.last_name + ', ' + row.data.user.first_name  }}</template>
                    </Column>

                    <Column 
                        field="created_at"
                        :sortable="true" 
                        header="Creado" 
                        :style="{width:'130px'}">
                        <template #body="row">{{ (row.data.created_at != null) ? $moment.utc(row.data.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") : '' }}</template>
                    </Column>

                </DataTable>
            </div>

            <div class="col-12 text-center">
                <Loading v-show="isLoading"/>   
            </div>

            <div class="col-12 text-center" v-if="!isLoading && !dataSet.length && !group.length">
                <h3 class="font-semibold">SIN RESULTADOS PARA MOSTRAR</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import apiService from '../../services/apiService';
    import Divider from 'primevue/divider';
    import Message from 'primevue/message';
    import { FilterMatchMode } from 'primevue/api';

    export default {
        data(){
            return{
                isLoading: false,
                init: true,
                filter: {
                    nights: [],
                    user: null
                },
                users: [],
                dataSet: [],
                group: [],
                total: null,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'amount': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'person.username': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'person.last_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'person.first_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'user.first_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'user.last_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                },
                maxDate: null
            }
        },

        components: {
            Divider,
            Message
        },
        created(){
            this.maxDate = new Date();
        },
        mounted() {
            this.index()
        },

        methods: {
            async index(){
                apiService.User.get()
                .then((result) => {
                    this.users = result
                })
            },
            async handleFilter(){
                if(this.filter.nights && this.filter.user){
                    this.isLoading = true
                    apiService.Reservation.filter(this.filter)
                    .then((result) => {
                        if(result.status == 'success'){
                            this.dataSet = result.detail
                            this.total = result.total
                            this.group = result.group
                            this.isLoading = false
                        }else{
                            this.FormErrors(result)
                            this.isLoading = false
                        }
                        this.isLoading = false
                    })
                }else{
                    this.FormRequestFail('Debe seleccionar al menos un usuario y una fecha para continuar.', true)
                }

            },
            getSumAmounts(){
                var group = 0;
                if(this.group.length > 0){
                    this.group.forEach(row => {
                       group+= parseFloat(row.group);
                    });
                }
                return group;
            },
            exportCSV() {
                this.$refs.dtDonations.exportCSV();
            },
            handleNight(nights){
                this.filter.nights = nights;
            }
        }
    }
</script>
